import { Link } from "react-router-dom";
import ContactMe from "./contactme";
const Work = (props) => {
    window.scrollTo(0, 0);
    const experience = props?.aboutMe?.experience || [];
    const technicalSkills = props?.aboutMe?.technicalSkills || [];
    const professionalSkill = props?.aboutMe?.professionalSkill || [];
    const education = props?.aboutMe?.education || [];
    const awards = props?.aboutMe?.awards || [];
    const interests = props?.aboutMe?.interests || [];
    const projects = props?.aboutMe?.projects || [];
    const plugins = props?.aboutMe?.plugins || [];

    return (
        <div className="resume-body">
            <div className="row">
                <div className="resume-main col-12 col-lg-8 col-xl-9 pe-0 pe-lg-5">
                    <section className="work-section py-3">
                        <h3 className="text-uppercase resume-section-heading mb-4">Work Experiences</h3>
                        {

                            experience?.map((exp, index) => {
                                return (
                                    <div key={"exp-" + index} className="item mb-3">
                                        <div className="item-heading row align-items-center mb-2">
                                            <h4 className="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0"> {exp.position}</h4>
                                            <div
                                                className="item-meta col-12 col-md-6 col-lg-4 text-muted text-start text-md-end">
                                                {exp.company} | {exp.year}</div>

                                        </div>
                                        <div className="item-content">
                                            <p>{exp.description}</p>
                                            <h5 className="f-s-s col-12 col-md-6 col-lg-8 mb-2 mb-md-2 text-decoration-underline">Responsibilities</h5>
                                            <ul className="resume-list">
                                                {
                                                    exp?.responsibilities?.map((responsibility, index) => {
                                                        return <li key={"responsibility-" + index}>{responsibility}</li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                )

                            })
                        }

                    </section>


                    <section className="project-section py-3">
                        <h3 className="text-uppercase resume-section-heading mb-4">Projects</h3>
                        {
                            projects?.map((project, index) => {
                                return (
                                    <div key={"project-" + index} className="item mb-5">
                                        <div className="item-heading row align-items-center mb-2">
                                            <h4 className="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0 text-decoration-underline">{project.name}
                                            </h4>
                                            <div
                                                className="item-meta col-12 col-md-6 col-lg-4 text-muted text-start text-md-end">
                                                <a target="_blank" href={project.domain}>{project.domain}</a></div>

                                        </div>
                                        <div className="item-content">
                                            <p>{project.about}</p>
                                        </div>
                                        <div className="item-content">
                                            <img className="project-img" src={project.image} />
                                        </div>
                                    </div>
                                )
                            })
                        }


                    </section>
                </div>
                <aside className="resume-aside col-12 col-lg-4 col-xl-3 px-lg-4 pb-lg-4">
                    <section className="skills-section py-3">
                        <h3 className="text-uppercase resume-section-heading mb-4">Skills</h3>
                        <div className="item">
                            <h4 className="item-title">Technical</h4>
                            <ul className="list-unstyled resume-skills-list">
                                {
                                    technicalSkills?.map((skill, index) => {
                                        return <li key={"skill-" + index} className="mb-2">{skill}</li>
                                    })
                                }

                            </ul>
                        </div>
                        <div className="item">
                            <h4 className="item-title">Professional</h4>
                            <ul className="list-unstyled resume-skills-list">
                                {
                                    professionalSkill?.map((skill, index) => {
                                        return <li key={"skill-1-" + index} className="mb-2">{skill}</li>
                                    })
                                }
                            </ul>
                        </div>
                    </section>
                    <section className="education-section py-3">
                        <h3 className="text-uppercase resume-section-heading mb-4">Education</h3>
                        <ul className="list-unstyled resume-education-list">
                            {
                                education?.map((edu, index) => {

                                    return (
                                        <li key={"edu-" + index} className="mb-3">
                                            <h4 className="item-title">{edu?.position}</h4>
                                            <div className="resume-degree-org text-muted">{edu?.school}</div>
                                            <div className="resume-degree-time text-muted">{edu?.year}</div>
                                        </li>
                                    )
                                })
                            }

                        </ul>
                    </section>
                    <section className="education-section py-3">
                        <h3 className="text-uppercase resume-section-heading mb-4">Awards</h3>
                        <ul className="list-unstyled resume-awards-list">
                            {
                                awards?.map((award, index) => {

                                    return (
                                        <li key={"award-" + index} className="mb-3">
                                            <div className="font-weight-bold">{award?.award}</div>
                                            <div className="text-muted">{award?.company}</div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </section>
                    <section className="skills-section py-3">
                        <h3 className="text-uppercase resume-section-heading mb-4">Languages</h3>
                        <ul className="list-unstyled resume-lang-list">
                            <li className="mb-2">English</li>
                            <li className="mb-2">Hindi</li>
                            <li>Punjabi</li>
                        </ul>
                    </section>
                    <section className="skills-section py-3">
                        <h3 className="text-uppercase resume-section-heading mb-4">Interests</h3>
                        <ul className="list-unstyled resume-interests-list mb-0">

                            {
                                interests?.map((interest, index) => {

                                    return <li key={"interest" + index} className="mb-2">{interest.type}</li>

                                })
                            }

                        </ul>
                    </section>
                    <section className="skills-section py-3">
                        <h3 className="text-uppercase resume-section-heading mb-4">Plugins</h3>
                        <ul className="list-unstyled resume-interests-list mb-0">

                            {
                                plugins?.map((plugin, index) => {

                                    return <li key={"plugin" + index} className="mb-2">
                                        <div>
                                            <h6 className="f-s-s col-12 col-md-6 col-lg-8 mb-2 mb-md-2 text-decoration-underline">
                                                {plugin.name}
                                            </h6>
                                            <Link className="plugin-a" to={`plugin/${plugin?.name?.replace(/ /g, '-')}`}>click to learn more. </Link>
                                        </div>
                                        <div><img className="plugin-image" src={plugin.image} /></div>
                                    </li>

                                })
                            }

                        </ul>
                    </section>
                    <section>
                        <ContactMe />
                    </section>
                </aside>
            </div>
        </div>
    );
};

export default Work;