import { useState } from 'react';

const ContactMe = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [emailError, setEmailError] = useState('');
    const [messageError, setMessageError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [btnText, setBtnText] = useState('Send');
    
    const isValidEmail = () => {
        if (!email.trim()) {
            setEmailError('Required');
            return false;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setEmailError('Invalid email address');
            return false;
        } else {
            setEmailError('');
            return true;
        }
    }
    const isValidMessage = () => {
        if (!message.trim()) {
            setMessageError('Required');
            return false;
        } else if (!/^[a-zA-Z0-9 ]*$/i.test(message)) {
            setMessageError('Invalid message');
            return false;
        } else {
            setMessageError('');
            return true;
        }
    }
    function contactMe() {
        if (!isValidEmail()) {
            return false;
        }
        if (!isValidMessage()) {
            return false;
        }
        setSuccessMessage('');
        setBtnText('Posting...');
        setIsError(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email, message: message })
        };
        fetch('/API/ContactMe/ContactMe', requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log('asdad');
                if (data === 'error') {
                    setSuccessMessage('Opps! Error occured, please try again.');
                } else {
                    setIsError(false);
                    setSuccessMessage('Thank you for contacting me. Will get back to you.');
                }
                setBtnText('Send');
            }).catch(ex => {
                setIsError(true);
                setSuccessMessage('Opps! Error occured, please try again.');
                setBtnText('Send');
            });



    }
    return <section className="mb-4" id="contactMe">
        <h4 className="text-uppercase resume-section-heading mb-4">Contact Me</h4>
        <p className="w-responsive mx-auto mb-3">Do you have any questions? Please do not hesitate to contact me directly.</p>
        <div>
            <div className="col-md-12 mb-md-0 mb-5">
                <form className="mb-3">
                    <div>
                        <div className="md-form mb-0">
                            <label htmlFor="email" className="">Your email</label>
                            <input required type="email" name="email" className={`form-control ${emailError ? 'error-textbox' : ''}`} value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>
                    <div>
                        <div className="md-form">
                            <label htmlFor="message">Your message</label>
                            <textarea required type="text" name="message" rows="2" className={`form-control md-textarea ${messageError ? 'error-textbox' : ''}`} value={message} onChange={(e) => setMessage(e.target.value)}></textarea>

                        </div>
                    </div>
                </form>
                <div className="text-md-left">
                    <button type="button" className="btn btn-primary" onClick={contactMe} disabled={successMessage && !isError}>{btnText}</button>
                </div>
                <div className="status"></div>
                {
                    successMessage && <div className={successMessage && isError ? "error-msg" : "success-msg"}>
                        {successMessage}
                    </div>
                }
            </div>
        </div>
    </section>
};

export default ContactMe;