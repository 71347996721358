import { Outlet } from "react-router-dom";
import React, { useEffect } from 'react';
import Footer from "./footer";
import { useDispatch } from 'react-redux';
import { setAboutMe } from '../state/reducer'
const Layout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(`/resources/aboutme.json`);
      const json = await data.json();
      dispatch(setAboutMe(json));
    }
    fetchData();
  }, [])

  return (
    <>
      <Outlet />
      <Footer />
    </>
  )
};

export default Layout;