import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import { APIRoutes } from "../../common/constants";

const Article = () => {
    let { cat, name } = useParams();
    const [articles, setArticles] = useState([]);
    const [article, setArticle] = useState({});
    const getArticles = async () => {
        fetch(APIRoutes.getArticle.url, {
            method: 'GET'
        })
            .then(function (res) { return res.json(); })
            .then(function (json) {
                setArticles(json?.articles)
            }).catch(function (e) {
                setArticles({ title: 'No data found' })
            });;

    }
    const getArticle = async (category, topic) => {
        fetch(`/topic/${category}/${topic.replace(/ /g, '-').replace('.json', '')}.json`, {
            method: 'GET'
        })
            .then(function (res) { return res.json(); })
            .then(function (json) {
                setArticle(json);
                document.title = `Varun Sharma - ${category} - ${topic}`;
            }).catch(function (e) {
            });;

    }
    const myFunction = () => {
        var x = document.querySelector('#myTopnav');
        x.className === "topnav" ? x.className += " responsive" : x.className = "topnav";

    }
    const shareLink = (type, category, title) => {
        const tit = title.replace(/ /g, '-').replace('.json', '');
        if (type === 'FB') {
            window.open(`https://www.facebook.com/sharer/sharer.php?u=https://sharma-varun.com/article/${category}/${tit}`, '_blank');
        }
        else if (type === 'LI') {
            window.open(`https://www.linkedin.com/sharing/share-offsite/?url=https://sharma-varun.com/article/${category}/${tit}`, '_blank');
        }
        else if (type === 'WA') {
            window.open(`whatsapp://send?text==https://sharma-varun.com/article/${category}/${tit}`, '_blank');
        }
    }

    useEffect(() => {
        getArticles();
       
        if (cat && name) {
            getArticle(cat, name);
        }

    }, []);


    return (
        <div className="main-wrapper">
            <div className="container px-3 px-lg-5">
                <article className="resume-wrapper mx-auto theme-bg-light p-5 mb-5 my-5 shadow-lg">
                    <div className="main-wrapper">
                        <div className="topnav" id="myTopnav">
                            <Link to="/" className="active"><b>Home</b></Link>
                            {
                                articles?.map((article, index) => {
                                    return <div key={"article-" + index} className="dropdown-nav">
                                        <button className="dropbtn">{article?.category?.toUpperCase()}
                                            <i className="fa fa-caret-down"></i>
                                        </button>
                                        <div className="dropdown-nav-content">
                                            {
                                                article?.topics?.map((topic, topicindex) => {
                                                    return <a key={"topic-" + topicindex} className="mb-2" onClick={(e) => { getArticle(article?.category, topic) }}>{topic?.replace(/-/g, ' ').replace('.json', '')}</a>
                                                })
                                            }
                                        </div>
                                    </div>
                                })
                            }
                            <a className="icon" onClick={(e) => { myFunction() }}>&#9776;</a>
                        </div>
                        <div className="resume-body">
                            <div className="row">
                                <div className="resume-main pe-lg-5">

                                    <br />
                                    <section className="work-section py-3">

                                        <h3 className="text-uppercase resume-section-heading mb-4">
                                            {article?.heading || 'Select Topic'}

                                            {
                                                article?.heading && <ul className="list-style-share">
                                                    <li>
                                                        <a className="resume-link c-p"
                                                            onClick={() => { shareLink('FB', article?.category, article?.title) }}>
                                                            <span className="s-i fb-icon"></span> 
                                                        </a>
                                                        <a className="resume-link c-p"
                                                            onClick={() => { shareLink('LI', article?.category, article?.title) }}>
                                                           <span className="s-i li-icon"></span> 
                                                        </a>
                                                        <a className="resume-link c-p"
                                                            onClick={() => { shareLink('WA', article?.category, article?.title) }}>
                                                           <span className="s-i wa-icon"></span> 
                                                        </a>
                                                    </li>
                                                </ul>
                                            }
                                        </h3>

                                        <div className="item mb-3">
                                            <div className="item-heading row align-items-center mb-2">
                                                <h4 className="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">
                                                    {article?.title?.toUpperCase()}
                                                </h4>
                                                <div
                                                    className="item-meta col-12 col-md-6 col-lg-4 text-muted text-start text-md-end">
                                                    {article?.category?.toUpperCase()}</div>

                                            </div>
                                            <br />
                                            <div className="item-content description" dangerouslySetInnerHTML={{ __html: (article?.description || 'Select a topic from menu.') }}>
                                            </div>
                                        </div>


                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </div>

    );
};

export default Article;



