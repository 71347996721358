
import { Link } from "react-router-dom";
const Header = (props) => {

    document.title = 'Varun Sharma';
    const d = new Date();
    const age = d.getFullYear() - 1988;
    const experience = d.getFullYear() - 2012;

    const about = props?.about
        ?.replace('[age]', age)
        ?.replace('[country]', 'Bellevue - USA')
        ?.replace('[experience]', experience);

    return (
        <div>
            <div className="resume-header">
                <div className="row align-items-center">
                    <div className="resume-title col-12 col-md-6 col-lg-8 col-xl-9">

                        {/* <div className="resume-tagline mb-3 mb-md-0"><img className="logo-img" src="/resources/logo.png" /></div> */}
                        <h2 className="resume-name mb-0 text-uppercase">Varun Sharma

                        </h2>
                        <div className="resume-tagline mb-3 mb-md-0">Web Developer</div>

                    </div>
                    <div className="resume-contact col-12 col-md-6 col-lg-4 col-xl-3">

                        <ul className="list-unstyled mb-0 ul-style-none">
                            <li className="mb-2">
                                <a className="resume-link"
                                    href="https://www.facebook.com/sharma.g.chandigarh.wale" target="_blank">
                                    <span className="s-i fb-icon"></span>
                                </a>
                                <a className="resume-link "
                                    href="https://www.instagram.com/sharma_g_chandigarh_wale/" target="_blank">
                                    <span className="s-i insta-icon"></span>
                                </a>
                                <a className="resume-link"
                                    href="https://www.linkedin.com/in/varunsharma14" target="_blank">
                                    <span className="s-i li-icon"></span>
                                </a>
                            </li>
                            <li className="mb-2">
                                <span className="s-i call-icon f-r"></span>
                                <a className="resume-link" href="tel:#">425-230-7437</a>
                            </li>
                            <li className="mb-2"> <span className="s-i gm-icon f-r"></span><a
                                className="resume-link" href="mailto:vani6aug@gmail.com">vani6aug@gmail.com</a></li>
                            <li className="mb-2"><span className="s-i web-icon f-r"></span><a className="resume-link"
                                href="#">sharma-varun.com</a></li>
                            <li className="mb-2"><span className="s-i l-icon f-r"></span> Bellevue - USA</li>
                            <li className="mb-2"><span className="s-i rb-icon f-r"></span><Link className="resume-link" to="/article">Read Blogs</Link></li>
                             <li className="mb-2"><img className="mini-social" src="/resources/downarrow.gif" /><a className="resume-link" href="#contactMe">Contact me</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr />
            <div className="resume-intro py-3">
                <div className="row align-items-center">
                    <div className="col-12 col-md-3 col-xl-2 text-center">
                        <img className="resume-profile-image mb-3 mb-md-0 me-md-5  ms-md-0 rounded mx-auto"
                            src="/me.png" alt="image" />
                    </div>

                    <div className="col text-start">
                        <div className="mb-0" dangerouslySetInnerHTML={{ __html: about }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;