import { useSelector } from 'react-redux'
import Header from "./header";
import Work from "./work";

const Home = () => {
    const aboutMe = useSelector((state) => state.vstore?.aboutme);
    
    return (
        <div className="main-wrapper">
            <div className="container px-3 px-lg-5">
                <article className="resume-wrapper mx-auto theme-bg-light p-5 mb-5 my-5 shadow-lg">
                    <Header about={aboutMe.about} />
                    <hr />
                    <Work aboutMe={aboutMe} />
                </article>
            </div>
        </div>
    );
};

export default Home;