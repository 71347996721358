const Footer = () => {
  return (
    <footer className="footer text-center py-4">
      <small className="copyright text-muted">Designed with <span className="sr-only">love</span><i
        className="fas fa-heart"></i> by <a className="theme-link" href="http://famousplacesinworld.com"
          target="_blank">Varun Sharma</a></small>
    </footer>
  );
};

export default Footer;



