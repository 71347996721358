import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';

import Layout from "./components/layout";
import ArticleLayout from "./components/article-layout";
import Home from "./components/home/home";
import Plugin from "./components/plugin/plugin";
import CreateArticle from "./components/manage-article/create-article";
import Article from "./components/manage-article/article";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/plugin" element={<Plugin />} />
          <Route path="/plugin/:id" element={<Plugin />} />
        </Route>
        <Route element={<ArticleLayout />}>
          <Route path="/create-article" element={<CreateArticle />} />
          <Route path="/article" element={<Article />} />
          <Route index path="/article/:cat/:name" element={<Article />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
