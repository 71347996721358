import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
const Plugin = () => {
    window.scrollTo(0, 0);
    const { id } = useParams();
    const pluginName = id?.replace(/-/g, ' ') || 'My Data Store';
    const plugins = useSelector((state) => state.vstore?.aboutme.plugins);
    const plugin = plugins?.filter(plugin => plugin.name === pluginName)[0];
    return (
        <div className="main-wrapper">
            <div className="container px-3 px-lg-5">
                <article className="resume-wrapper mx-auto theme-bg-light p-5 mb-5 my-5 shadow-lg">
                    <div className="resume-body">
                        <div className="row">
                            <div className="resume-main pe-0 pe-lg-5">
                                <section className="project-section py-3">
                                    <img className="plugin-logo" src={plugin?.logo} />
                                    <div className="item mb-5">
                                        <div className="item-heading row align-items-center mb-2">
                                            <div
                                                className="item-meta text-muted text-start text-md-end">
                                                <Link to="/">Go to home</Link></div>
                                            <div
                                                className="item-meta text-muted text-start text-md-end">
                                                <a target="_blank" href={plugin?.usermanualLink}>Download user manual</a></div>
                                            <div
                                                className="item-meta text-muted text-start text-md-end">
                                                <a target="_blank" href={plugin?.downloadLink}>Download {plugin?.name} app for windows</a></div>
                                            {
                                            plugin?.youtubeLink && <div
                                                className="item-meta text-muted text-start text-md-end">
                                                <a className="resume-link"
                                                    href={plugin?.youtubeLink} target="_blank">
                                                    <img src="/resources/youtube.png" />
                                                </a></div>
                                            }

                                        </div>
                                        <div className="item-content">
                                            <p>{plugin?.about}</p>
                                        </div>

                                        <div className="item-content">
                                            <ul className="resume-list">
                                                {
                                                    plugin?.features?.map((feature, index) => {
                                                        return <li key={"responsibility-" + index}>
                                                            <h5 className="f-s-s col-12 col-md-6 col-lg-8 mb-2 mb-md-2 text-decoration-underline">{feature.title}</h5>
                                                            {feature.description}
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                            {
                                                plugin?.smallImages?.map((img, index) => {
                                                    return <img key={"img-" + index} className="plugin-img" src={img} />

                                                })
                                            }
                                        </div>
                                    </div>
                                </section>

                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    );
};

export default Plugin;