import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState'

export const vSlice = createSlice({
  name: 'vstore',
  initialState,
  reducers: {
    setAboutMe: (state, action) => {
      state.aboutme = action.payload.aboutme;
    }
  },
})


export const { setAboutMe, setFriendsList, setSongs } = vSlice.actions
export default vSlice.reducer