export const clientID = '596928712514-j41md3amhsgls3fevgi8jbic4qig0ecs.apps.googleusercontent.com';
export const profileCookie = 'fid';
export const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const noSpecialChars = /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`]/g;
export const fewSpecialChars = /[\%\#\^\&\*\+\=\<\>\{\}\[\]\;\'\"\|\~\`]/g;
export const numberChars = /\D/g;
export const scrollToElement = (eleId) => {
    document.querySelector(`#${eleId}`).scrollIntoView({
        behavior: 'smooth'
    });
}
export const convertDate = (date) => {
    return date.toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "numeric" });
}
export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const isJSON = (str) => {
    try {
        return (JSON.parse(str) && !!str);
    } catch (e) {
        return false;
    }
}

export const noSpecialCharacter = (value, type) => {
    if (type === 'NOSPECIAL') {
        return value.replace(noSpecialChars, "");
    } else if (type === 'NUMBER') {
        return value.replace(numberChars, "");
    } else {
        return value.replace(fewSpecialChars, "");
    }
}
export const APIRoutes = {

    signIn: {
        url: "/Home/signIn",
        type: 'POST'
    },
    signOut: {
        url: "/Home/signout",
        type: 'GET'
    },
    createArticle: {
        url: '/API/Blog/create-article',
        type: 'POST'
    },
    getArticle: {
        url: '/API/Blog/get-article',
        type: 'GET'
    }
};
