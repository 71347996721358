import { Outlet } from "react-router-dom";
import Footer from "./footer"; 
const ArticleLayout = () => {
  return (
    <>
      <Outlet />
      <Footer />
    </>
  )
};

export default ArticleLayout;