import { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { APIRoutes } from "../../common/constants";
import { googleLogout, GoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import { DefaultEditor } from 'react-simple-wysiwyg';

const CreateArticle = () => {
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [heading, setHeading] = useState('');
    const [category, setCategory] = useState('');
    const [html, setHtml] = useState('');
    const [isLogedIn, setIsLogedIn] = useState(false);

    const createArticle = async () => {
        if (title && heading && category && html) {
            fetch(APIRoutes.createArticle.url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    title,
                    heading,
                    category,
                    description: html
                })
            })
                .then(function (res) { return res.json(); })
                .then(function (json) {
                    if (json.data === 'success') {
                        setTitle('');
                        setHeading('');
                        setCategory('');
                        setHtml('');
                    }
                }).catch(function (e) {
                });;
        }
    }
    const fetchData = async (profileDetail) => {

        fetch(APIRoutes.signIn.url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'gid': profileDetail?.sub,
                'email': profileDetail?.email,
                'name': profileDetail?.name
            })
        })
            .then(function (res) { return res.json(); })
            .then(function (json) {
                setIsLogedIn(true)
            }).catch(function (e) {
            });;

    }
    const onSuccessRes = (res) => {
        const data = jwtDecode(res.credential);
        fetchData(data);
    };
    const onFailure = (err) => {
    };
    const logOut = () => {
        googleLogout();
        signOut();
        navigate('/');

    };
    const signOut = async () => {
        fetch(APIRoutes.signOut.url, { method: APIRoutes.signOut.type }).then(function () { }).catch(function (e) { });
    }
    const myFunction = () => {
        var x = document.querySelector('#myTopnav');
        x.className === "topnav" ? x.className += " responsive" : x.className = "topnav";

    }
    return (
        <div className="main-wrapper">
            <div className="container px-3 px-lg-5">
                <article className="resume-wrapper mx-auto theme-bg-light p-5 mb-5 my-5 shadow-lg">
                    <div className="main-wrapper">
                        <div className="topnav" id="myTopnav">
                            <Link to="/" className="active"><b>Home</b></Link>
                            <div className="dropdown-nav">
                                <button className="dropbtn">Log in
                                    <i className="fa fa-caret-down"></i>
                                </button>
                                <div className="dropdown-nav-content">
                                    {!isLogedIn && <GoogleLogin
                                        theme="filled_black"
                                        shape="pill"
                                        onSuccess={credentialResponse => {
                                            onSuccessRes(credentialResponse);
                                        }}
                                        onError={() => {
                                            onFailure('Login Failed');
                                        }}
                                        useOneTap
                                    />}
                                    {isLogedIn && <a className="mb-2" onClick={(e) => { logOut() }}>Log out</a>}
                                </div>
                            </div>
                            <a className="icon" onClick={(e) => { myFunction() }}>&#9776;</a>
                        </div>
                        <br />
                        <h4 className="text-uppercase resume-section-heading mb-4">Create Article</h4>
                        <div>
                            <div className="col-md-12 mb-md-0 mb-5">
                                <form className="mb-3">
                                    <div>
                                        <div className="md-form mb-0">
                                            <label htmlFor="title" className="">Title</label>
                                            <input required type="text" name="title" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="md-form mb-0">
                                            <label htmlFor="heading" className="">Heading</label>
                                            <input required type="text" name="heading" className="form-control" value={heading} onChange={(e) => setHeading(e.target.value)} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="md-form mb-0">
                                            <label htmlFor="category" className="">Category</label>
                                            <input required type="text" name="category" className="form-control" value={category} onChange={(e) => setCategory(e.target.value)} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="md-form mb-0">
                                            <label htmlFor="description" className="">Description</label>
                                            <DefaultEditor name="description" value={html} onChange={(e) => setHtml(e.target.value)} />
                                        </div>
                                    </div>
                                </form>
                                <div className="text-md-left">
                                    {isLogedIn && <button type="button" className="btn btn-primary" onClick={(e) => createArticle()} >Create</button>}
                                </div>
                                <div className="status"></div>

                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </div>

    );
};

export default CreateArticle;



